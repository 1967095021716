import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Box,
} from '@mui/material';
import { useTranslation } from 'next-i18next';

import { FormErrors } from 'views/common/components';

const PREFIX = 'BillingAddress';

const classes = {
  darkTitle: `${PREFIX}-lightTitle`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.darkTitle}`]: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1.25),
  },
}));

const BillingAddress = (props) => {
  const {
    creditCardForm,
    countries,
    states,
    handleBillingAddressChange,
    handleCountryChange,
    creditCardError,
    addCreditCardView,
  } = props;

  const { t } = useTranslation('fe_er_credit_card_info');

  return (
    <Root>
      <Typography variant="h4" className={classes.darkTitle}>
        {t('fe_er_credit_card_info:billing_address')}
      </Typography>
      {creditCardError?.errors?.base && (
        <Box>
          <FormErrors
            errorMsgs={creditCardError}
            showBaseErrors={creditCardError?.errors?.base}
          />
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item sm={12} md={6} className="BAGridorder">
          <TextField
            id="name"
            fullWidth
            required
            color="secondary"
            type="text"
            label={t('fe_er_credit_card_info:name')}
            variant={addCreditCardView ? 'filled' : 'outlined'}
            name="name"
            value={creditCardForm.billing_address_info?.name || ''}
            onChange={handleBillingAddressChange}
            error={Boolean(creditCardError?.errors?.name)}
          />
          {creditCardError?.errors && (
            <FormErrors errorMsgs={creditCardError} errorField="name" />
          )}
        </Grid>

        {addCreditCardView && (
          <Grid item sm={12} md={6} className="BAGridorder">
            <TextField
              id="email"
              fullWidth
              required
              color="secondary"
              type="email"
              label={t('fe_er_credit_card_info:email_address')}
              variant="filled"
              name="email"
              value={creditCardForm.billing_address_info?.email || ''}
              onChange={handleBillingAddressChange}
              error={Boolean(creditCardError?.errors?.email)}
            />
            {creditCardError?.errors && (
              <FormErrors errorMsgs={creditCardError} errorField="email" />
            )}
          </Grid>
        )}

        <Grid item sm={12} md={6} className="BAGridorder">
          <FormControl
            variant={addCreditCardView ? 'filled' : 'outlined'}
            fullWidth
            required
            error={Boolean(creditCardError?.errors?.country_id)}>
            <InputLabel color="secondary">
              {t('fe_er_credit_card_info:country')}
            </InputLabel>
            <Select
              value={creditCardForm.billing_address_info?.country_id || null}
              onChange={handleCountryChange}
              color="secondary"
              label={t('fe_er_credit_card_info:country')}
              name="country_id"
              error={Boolean(creditCardError?.errors?.country_id)}>
              {countries?.map((country) => (
                <MenuItem value={country.id} key={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {creditCardError?.errors && (
            <FormErrors errorMsgs={creditCardError} errorField="country_id" />
          )}
        </Grid>

        <Grid item sm={12} md={6} className="BAGridorder">
          <FormControl
            variant={addCreditCardView ? 'filled' : 'outlined'}
            fullWidth
            required
            error={Boolean(creditCardError?.errors?.region_id)}>
            <InputLabel color="secondary">
              {t('fe_er_credit_card_info:state_province')}
            </InputLabel>
            <Select
              value={creditCardForm.billing_address_info?.region_id || null}
              disabled={!states || states?.length == 0}
              onChange={handleBillingAddressChange}
              color="secondary"
              label={t('fe_er_credit_card_info:state_province')}
              name="region_id">
              {states?.map((state) => (
                <MenuItem value={state.id} key={state.id}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {creditCardError?.errors && (
            <FormErrors errorMsgs={creditCardError} errorField="region_id" />
          )}
        </Grid>

        <Grid item sm={12} md={6} className="BAGridorder">
          <TextField
            id="city"
            fullWidth
            required
            color="secondary"
            type="text"
            label={t('fe_er_credit_card_info:city')}
            variant={addCreditCardView ? 'filled' : 'outlined'}
            name="city"
            value={creditCardForm.billing_address_info?.city || ''}
            onChange={handleBillingAddressChange}
            error={Boolean(creditCardError?.errors?.city)}
          />
          {creditCardError?.errors && (
            <FormErrors errorMsgs={creditCardError} errorField="city" />
          )}
        </Grid>

        <Grid item sm={12} md={6} className="BAGridorder">
          <TextField
            {...(addCreditCardView && { required: true })}
            id="address"
            fullWidth
            color="secondary"
            type="text"
            label={t('fe_er_credit_card_info:address')}
            variant={addCreditCardView ? 'filled' : 'outlined'}
            name="address"
            value={creditCardForm.billing_address_info?.address || ''}
            onChange={handleBillingAddressChange}
            error={Boolean(creditCardError?.errors?.address)}
          />
          {creditCardError?.errors && (
            <FormErrors errorMsgs={creditCardError} errorField="address" />
          )}
        </Grid>

        <Grid item sm={12} md={6} className="BAGridorder">
          <TextField
            id="address2"
            fullWidth
            color="secondary"
            type="text"
            label={t('fe_er_credit_card_info:address2')}
            variant={addCreditCardView ? 'filled' : 'outlined'}
            name="address2"
            value={creditCardForm.billing_address_info?.address2 || ''}
            onChange={handleBillingAddressChange}
            error={Boolean(creditCardError?.errors?.address2)}
          />
          {creditCardError?.errors && (
            <FormErrors errorMsgs={creditCardError} errorField="address2" />
          )}
        </Grid>

        <Grid item sm={12} md={6} className="BAGridorder">
          <TextField
            id="postal_code"
            fullWidth
            required
            color="secondary"
            type="text"
            label={t('fe_er_credit_card_info:postal_code')}
            variant={addCreditCardView ? 'filled' : 'outlined'}
            name="postal_code"
            value={creditCardForm.billing_address_info?.postal_code || ''}
            onChange={handleBillingAddressChange}
            error={Boolean(creditCardError?.errors?.postal_code)}
          />
          {creditCardError?.errors && (
            <FormErrors errorMsgs={creditCardError} errorField="postal_code" />
          )}
        </Grid>
      </Grid>
    </Root>
  );
};

export default BillingAddress;
