import React, { useState } from 'react';
import {
  Box, TextField, Button, Grid, useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'next-i18next';

import { CheckBoxCard, FormErrors } from 'views/common/components';
import Typography500 from 'views/common/components/UI/Typography500';

const PREFIX = 'ApplyCredit';

const classes = {
  applyText: `${PREFIX}-applyText`,
  codeGrid: `${PREFIX}-codeGrid`,
  applyBtn: `${PREFIX}-applyBtn`,
  codeRemoveIcon: `${PREFIX}-codeRemoveIcon`,
  codeAppliedIcon: `${PREFIX}-codeAppliedIcon`,
  flex: `${PREFIX}-flex`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.applyText}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.codeGrid}`]: {
    width: '60%',
    '& .MuiOutlinedInput-root': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  [`& .${classes.applyBtn}`]: {
    width: '40%',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    borderColor: theme.palette.secondary.main,
  },
  [`& .${classes.codeRemoveIcon}`]: {
    marginRight: theme.spacing(0.75),
  },
  [`& .${classes.codeAppliedIcon}`]: {
    marginRight: theme.spacing(0.75),
  },
  [`& .${classes.flex}`]: {
    display: 'flex',
  },
}));

const ApplyCredit = (props) => {
  const theme = useTheme();

  const {
    code,
    handleCodeChange,
    giftCodeError,
    applyCode,
    creditIsApplied,
    handleRemove,
  } = props;

  const { t } = useTranslation('fe_er_apply_credit');

  const [checked, setChecked] = useState(false);

  return (
    <StyledBox mt={2}>
      <Typography500 variant="h4" className={classes.applyText}>
        {t('fe_er_apply_credit:apply_store_credit')}
      </Typography500>
      <Box mb={2.5}>
        <CheckBoxCard
          title={t('fe_er_apply_credit:apply_store_credit')}
          checked={checked}
          onChange={() => {
            handleCodeChange('');
            setChecked((prev) => !prev);
          }}
          radioBtnPostion="left"
          className={classes.applyCheckBox}
        />
      </Box>
      {checked && (
        <Box>
          <Grid container>
            <Grid
              className={classes.flex}
              item
              xs={creditIsApplied ? 8 : 12}
              md={creditIsApplied ? 10 : 12}>
              <TextField
                value={code || ''}
                type="text"
                variant="outlined"
                label={t('fe_er_apply_credit:claim_code')}
                onChange={(e) => handleCodeChange(e.target.value)}
                className={classes.codeGrid}
                error={Boolean(giftCodeError?.errors)}
              />
              <Button
                onClick={() => (creditIsApplied ? null : applyCode())}
                variant={creditIsApplied ? 'contained' : 'outlined'}
                className={classes.applyBtn}
                color="secondary">
                {creditIsApplied ? (
                  <>
                    <CheckIcon className={classes.codeAppliedIcon} />
                    {t('fe_er_apply_credit:applied')}
                  </>
                ) : (
                  t('fe_er_apply_credit:apply')
                )}
              </Button>
            </Grid>
            {creditIsApplied && (
              <Grid className={classes.flex} item xs={4} md={2}>
                <Button fullWidth onClick={handleRemove}>
                  <CancelIcon className={classes.codeRemoveIcon} />
                  {t('fe_er_apply_credit:remove')}
                </Button>
              </Grid>
            )}
          </Grid>
          {creditIsApplied && (
            <Box
              component="p"
              fontSize="h6.fontSize"
              mt={0.5}
              color={theme.palette.info.dark}>
              {t('fe_er_apply_credit:credit_applied_text')}
            </Box>
          )}
          {giftCodeError?.errors && (
            <FormErrors errorMsgs={giftCodeError} errorField="claim_code" />
          )}
          {giftCodeError?.errors && (
            <FormErrors errorMsgs={giftCodeError} errorField="amount" />
          )}
        </Box>
      )}
    </StyledBox>
  );
};

export { ApplyCredit };
