import React from 'react';
import { styled } from '@mui/material/styles';
import {
  TextField,
  Box,
  Button,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Alert,
} from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import LockIcon from '@mui/icons-material/Lock';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';

import { FormErrors, ImgBox } from 'views/common/components';
import BillingAddress from 'views/common/components/UI/Payment/BillingAddress';
import dayjs from 'dayjs';

const PREFIX = 'CreditCardInfo';

const classes = {
  darkTitle: `${PREFIX}-darkTitle`,
  hintDes: `${PREFIX}-hintDes`,
  hintIcn: `${PREFIX}-hintIcn`,
  hintImg: `${PREFIX}-hintImg`,
  ccv: `${PREFIX}-ccv`,
  CCIImgBox: `${PREFIX}-CCIImgBox`,
  CCIImgBoxGoogleTrusted: `${PREFIX}-CCIImgBoxGoogleTrusted`,
  CCIImgBoxSsl: `${PREFIX}-CCIImgBoxSsl`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.darkTitle}`]: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(4),
  },

  [`& .${classes.hintDes}`]: {
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: '300',
    color: theme.palette.grey[400],
  },

  [`& .${classes.hintIcn}`]: {
    color: theme.palette.grey['300'],
    marginRight: theme.spacing(0.5),
  },

  [`& .${classes.hintImg}`]: {
    display: 'inline-block',
    height: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
    '&:first-child': {
      marginRight: theme.spacing(1),
    },
  },

  [`& .${classes.ccv}`]: {
    height: theme.spacing(7),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    '& img': {
      height: theme.spacing(3),
      '&:first-child': {
        marginRight: theme.spacing(1),
      },
    },
  },
  [`& .${classes.CCIImgBox}`]: {
    '&.IBimgbox': {
      [theme.breakpoints.down('md')]: {
        width: theme.spacing(5),
      },
    },
  },
  [`& .${classes.CCIImgBoxGoogleTrusted}`]: {
    marginRight: theme.spacing(1),
    '&.IBimgbox': {
      [theme.breakpoints.down('md')]: {
        width: theme.spacing(12.5),
      },
    },
  },
  [`& .${classes.CCIImgBoxSsl}`]: {
    '&.IBimgbox': {
      [theme.breakpoints.down('md')]: {
        width: theme.spacing(9.25),
      },
    },
  },
  '& .CCIErrorColor': {
    color: theme.palette.error.main,
  },
  '& .CCICheckPreferred': {
    marginTop: theme.spacing(2),
    '& .MuiTypography-root': {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'flex-start',
      '& .MuiCheckbox-root': {
        paddingTop: 0,
      },
    },
  },
  '& .CCIDefaultAlert': {
    '& .MuiSvgIcon-root': {
      color: theme.palette.warning.dark,
    },
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
}));

const CreditCardInfo = (props) => {
  const {
    creditCardForm,
    handleCreditCardFormChange,
    handleDateChange,
    showBillingAddress = false,
    handleBillingAddressChange,
    addNewCreditCard,
    creditCardError,
    states,
    selectedCountry,
    countries,
    handleCountryChange,
    className,
    addCreditCardView,
    billingAddress,
    prevBillingAddressId,
    handleBillingAddressSelectChange,
    showEmailField = addCreditCardView,
    showSaveNewCardBtn = !addCreditCardView,
    canSetAsDefaultCard,
    handleDefaultMethodChange,
  } = props;

  const { t } = useTranslation('fe_er_credit_card_info');

  const appState = useSelector((state) => state.globalData.appState);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <StyledBox className={className}>
      <Typography variant="h4" color="secondary" className={classes.darkTitle}>
        {t('fe_er_credit_card_info:credit_card_info')}
      </Typography>

      <Grid container alignItems="flex-start" spacing={2} pb={2}>
        <Grid item xs={12} md={12}>
          <Box display="flex" alignItems="flex-start">
            <LockIcon className={classes.hintIcn} />
            <Typography className={classes.hintDes} variant="caption">
              {t('fe_er_credit_card_info:secure_form_text')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {creditCardError?.errors?.base && (
        <Box>
          <FormErrors
            errorMsgs={creditCardError}
            showBaseErrors={creditCardError?.errors?.base}
          />
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            value={creditCardForm?.name || ''}
            name="name"
            type="text"
            fullWidth
            required
            color="secondary"
            label={t('fe_er_credit_card_info:name_on_card')}
            variant={addCreditCardView ? 'filled' : 'outlined'}
            onChange={(e) => handleCreditCardFormChange(e)}
            error={Boolean(creditCardError?.errors?.name)}
          />
          {creditCardError?.errors && (
            <FormErrors errorMsgs={creditCardError} errorField="name" />
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            value={creditCardForm?.number || ''}
            name="number"
            type="text"
            fullWidth
            required
            color="secondary"
            label={t('fe_er_credit_card_info:card_number')}
            variant={addCreditCardView ? 'filled' : 'outlined'}
            onChange={(e) => handleCreditCardFormChange(e)}
            error={Boolean(creditCardError?.errors?.number)}
          />
          {creditCardError?.errors && (
            <FormErrors errorMsgs={creditCardError} errorField="number" />
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <DatePicker
            required
            fullWidth
            variant="inline"
            inputVariant="outlined"
            color="secondary"
            minDate={dayjs(new Date())}
            closeOnSelect
            name="expiration_date"
            openTo="year"
            format="MM/YYYY"
            placeholder="mm/yyyy"
            views={['year', 'month']}
            mask="__/____"
            label={t('fe_er_credit_card_info:expiration_date')}
            disableMaskedInput={false}
            value={
              (creditCardForm?.expiration_date
                && dayjs(creditCardForm.expiration_date))
              || null
            }
            onChange={(date) => handleDateChange(date)}
            slotProps={{
              textField: {
                fullWidth: true,
                required: true,
                variant: addCreditCardView ? 'filled' : 'outlined',
                error:
                  Boolean(creditCardError?.errors?.expiration_month)
                  || Boolean(creditCardError?.errors?.year)
                  || Boolean(creditCardError?.errors?.month)
                  || Boolean(creditCardError?.errors?.expiration_year),
                helperText:
                  (Boolean(creditCardError?.errors?.expiration_month)
                    || Boolean(creditCardError?.errors?.year)
                    || Boolean(creditCardError?.errors?.month)
                    || Boolean(creditCardError?.errors?.expiration_year))
                  && t('fe_er_credit_card_info:expiration_error'),
              },
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                value={creditCardForm?.verification_value || ''}
                name="verification_value"
                type="text"
                fullWidth
                required
                color="secondary"
                label={t('fe_er_credit_card_info:ccv')}
                variant={addCreditCardView ? 'filled' : 'outlined'}
                onChange={(e) => handleCreditCardFormChange(e)}
                error={Boolean(creditCardError?.errors?.verification_value)}
              />
              {creditCardError?.errors && (
                <FormErrors
                  errorMsgs={creditCardError}
                  errorField="verification_value"
                />
              )}
            </Grid>

            <Grid item xs={6}>
              <Box className={classes.ccv}>
                <img src="/imgs/payments/ccv.svg" alt="" />
                <img src="/imgs/payments/ccv-american-express.svg" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {addCreditCardView && (
          <Grid item sm={12} md={6} className="BAGridorder">
            <FormControl
              variant="filled"
              fullWidth
              required
              error={Boolean(
                creditCardError?.errors?.prev_billing_address_card_id,
              )}>
              <InputLabel color="secondary">
                {t('fe_er_credit_card_info:billing_address')}
              </InputLabel>
              <Select
                value={prevBillingAddressId}
                onChange={handleBillingAddressSelectChange}
                color="secondary"
                label={t('fe_er_credit_card_info:billing_address')}
                name="prev_billing_address_card_id">
                {billingAddress?.map((address) => (
                  <MenuItem value={address.id} key={address.id}>
                    {address.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {creditCardError?.errors && (
              <FormErrors
                errorMsgs={creditCardError}
                errorField="prev_billing_address_card_id"
              />
            )}
          </Grid>
        )}
      </Grid>
      {canSetAsDefaultCard && creditCardForm?.defaultMethodOptions && (
        <>
          <FormControlLabel
            className="CCICheckPreferred"
            control={(
              <Checkbox
                checked={creditCardForm.defaultMethodOptions.checked}
                disabled={creditCardForm.defaultMethodOptions.disabled}
                onChange={handleDefaultMethodChange}
              />
            )}
            label={t('fe_er_credit_card_info:set_default_payment')}
          />
          {creditCardForm.defaultMethodOptions.disabled && (
            <Alert
              severity="warning"
              icon={<ReportProblemIcon fontSize="small" />}
              className="CCIDefaultAlert">
              {t('fe_er_credit_card_info:default_payment_alert')}
            </Alert>
          )}
        </>
      )}

      <Box mt={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box display="flex">
              <ImgBox
                className={classes.CCIImgBoxGoogleTrusted}
                imgObjectFit="contain"
                imgHeight="2rem"
                imgWidth="6.25rem"
                image={{
                  src: '/imgs/payments/google-trusted.jpg',
                  alt: 'Google-trusted',
                }}
              />
              <ImgBox
                className={classes.CCIImgBoxSsl}
                imgObjectFit="contain"
                imgHeight="2rem"
                imgWidth="4.625rem"
                image={{
                  src: '/imgs/payments/ssl-secure.jpg',
                  alt: 'Ssl-secure',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              justifyContent={isLargeScreen ? 'flex-end' : 'start'}>
              <Box mr={1}>
                <ImgBox
                  className={classes.CCIImgBox}
                  imgObjectFit="contain"
                  imgHeight="1.5rem"
                  imgWidth="2.5rem"
                  image={{
                    src: '/imgs/payments/american_express.svg',
                    alt: 'American_express',
                  }}
                />
              </Box>
              <Box mr={1}>
                <ImgBox
                  className={classes.CCIImgBox}
                  imgObjectFit="contain"
                  imgHeight="1.5rem"
                  imgWidth="2.5rem"
                  image={{
                    src: '/imgs/payments/jcb.svg',
                    alt: 'JCB',
                  }}
                />
              </Box>
              <Box mr={1}>
                <ImgBox
                  className={classes.CCIImgBox}
                  imgObjectFit="contain"
                  imgHeight="1.5rem"
                  imgWidth="2.5rem"
                  image={{
                    src: '/imgs/payments/discover.svg',
                    alt: 'discover',
                  }}
                />
              </Box>
              <Box mr={1}>
                <ImgBox
                  className={classes.CCIImgBox}
                  imgObjectFit="contain"
                  imgHeight="1.5rem"
                  imgWidth="2.5rem"
                  image={{
                    src: '/imgs/payments/master.svg',
                    alt: 'Master',
                  }}
                />
              </Box>
              <Box mr={1}>
                <ImgBox
                  className={classes.CCIImgBox}
                  imgObjectFit="contain"
                  imgHeight="1.5rem"
                  imgWidth="2.5rem"
                  image={{
                    src: '/imgs/payments/visa.svg',
                    alt: 'visa',
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {showBillingAddress && (
        <Box mt={3}>
          <BillingAddress
            handleBillingAddressChange={handleBillingAddressChange}
            creditCardForm={creditCardForm}
            creditCardError={{
              errors: creditCardError?.errors?.billing_address_info,
            }}
            handleCountryChange={handleCountryChange}
            states={states}
            selectedCountry={selectedCountry}
            countries={countries}
            addCreditCardView={showEmailField}
          />
        </Box>
      )}

      {appState.current_user
        && !appState.current_user.privileged
        && showSaveNewCardBtn && (
          <Box display="flex" mt={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={addNewCreditCard}>
              {t('fe_er_credit_card_info:save_new_card')}
            </Button>
          </Box>
      )}
    </StyledBox>
  );
};

export default CreditCardInfo;
